import React from "react"
/** @jsx jsx */
import { Box, Grid, jsx } from "theme-ui"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import HeaderBlockColor from "../components/headerBlockColor"
import FAQs from "../components/FAQs"

const FAQSPage = () => {
  const data = useStaticQuery(graphql`
    query FAQsQuery {
      allStrapiFaQs {
        nodes {
          Title
          description
          question_list {
            answer
            question
          }
          cms_title
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Frequently Asked Questions" />
      <HeaderBlockColor />
      <Box
        sx={{
          mx: 3,
          background:
            "linear-gradient(90deg, rgba(244,5,88,1) 0%, rgba(226,30,54,1) 100%)",
          transform: "rotate(-3deg) skew(-3deg) !important",
          py: 3,
          px: 3,
          position: "relative",
          mt: "-100px",
          mb: 3,
          display: "inline-block",
          "& h1": {
            color: "white",
            transform: "rotate(3deg) skew(3deg) !important",
          },
        }}
      >
        <h1>FAQ's</h1>
      </Box>
      {data.allStrapiFaQs.nodes.map((item, index) => {
        return <FAQs content={item} key={`faq-${index}`} title="cms" />
      })}
    </Layout>
  )
}

export default FAQSPage
