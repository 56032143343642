/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box } from "@theme-ui/components"
import React, { useState, useCallback } from "react"
import { Collapse } from "react-collapse"
import { FaArrowDown } from "react-icons/fa"
import SanitizedHTMLBodyCopy from "./sanitizedHTMLBodyCopy"

const FAQItem = ({ faq }) => {
  const [isButtonCollapseOpen, setIsButtonCollapseOpen] = useState(false)
  const onClick = useCallback(
    () => setIsButtonCollapseOpen(!isButtonCollapseOpen),
    [isButtonCollapseOpen]
  )
  return (
    <Box
      className={`open-${isButtonCollapseOpen}`}
      sx={{
        transition: "all 0.3s ease-in-out",
        border: theme => `3px solid ${theme.colors.accent}`,
        color: "white",
        mx: [3, 4, 5],
        mb: 3,
        px: 3,
        textAlign: "left",
        "& h2": {
          color: "accent",
          fontSize: [3, 4, 4],
        },
        "&.open-true": {
          backgroundColor: "accent",
          color: "white",
          "& h2": {
            color: "white",
          },
          "& .bodyCopy": {
            pb: 3,
            fontSize: [3, 4, 4],
          },
        },
      }}
    >
      <a
        aria-controls="open biog"
        aria-expanded={isButtonCollapseOpen}
        onClick={onClick}
        type="button"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "&:hover": {
            cursor: "pointer",
          },
          "&  svg.icon": {
            fontSize: 4,
            transform: "rotate(0deg)",
            transition: "all 0.2s ease-in-out",
            color: "accent",
          },
          "&  svg.icon.true": {
            color: "white",
            transform: "rotate(-180deg)",
          },
        }}
      >
        <h2>{faq.question} </h2>
        <Box>
          <FaArrowDown className={`icon ${isButtonCollapseOpen}`} />
        </Box>
      </a>

      <Collapse isOpened={isButtonCollapseOpen}>
        <SanitizedHTMLBodyCopy content={faq.answer} />
      </Collapse>
    </Box>
  )
}
export default FAQItem
