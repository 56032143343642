/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box } from "@theme-ui/components"
import React, { useState, useCallback } from "react"
import FAQItem from "./FAQItem"

const FAQs = ({ content, title }) => {
  let titleToDisplay
  if (title === "cms") {
    // console.log("title true", title)
    titleToDisplay = content.cms_title
  } else {
    // console.log("title", title)
    titleToDisplay = content.Title
  }

  return (
    <section
      sx={{
        m: "0 auto",
        textAlign: "center",
        h1: { fontSize: [5, 5, 5], mb: [3, 4, 4] },
      }}
    >
      <h1>{titleToDisplay}</h1>
      {content.question_list.map((faq, index) => {
        return <FAQItem faq={faq} key={`faq${index}`} />
      })}
    </section>
  )
}
export default FAQs
